const btnFaleConosco = document.querySelector<HTMLElement>("#fale-conosco-btn");
const popupFaleConosco = document.querySelector<HTMLElement>(
    ".header__fale-conosco-popup"
);

const openModal = (event) => {
    event.preventDefault();
    if (popupFaleConosco) {
        popupFaleConosco.classList.add("header__fale-conosco-popup--open");
    }
};

const closeModal = () => {
    if (popupFaleConosco) {
        popupFaleConosco.classList.remove("header__fale-conosco-popup--open");
    }
};

btnFaleConosco?.addEventListener("click", openModal);
btnFaleConosco?.addEventListener("blur", closeModal);
