
export default class Router {
  constructor(routes) {
    this.routes = routes;

    this.attachEvents();
    this.check();
  }

  attachEvents() {
    const links = document.querySelectorAll('[href]');
    const { routes } = this;

    Array.from(links).forEach((el) => {
      el.addEventListener('click', (e) => {
        const route = routes.find((r) => r.path === e.currentTarget.pathname);

        if (route && route.criteria()) {
          e.preventDefault();
          window.history.pushState({}, '', route.path);
          route.action();
        }
      });
    });
  }

  check() {
    this.routes.forEach((route) => {
      if (route.path === window.location.pathname) {
        route.action();
      }
    });
  }
}
