import Swal, { SweetAlertResult } from "sweetalert2";

document.addEventListener("DOMContentLoaded", () => {
    if (!getCookie("canopusCookies")) {
        Swal.fire({
            title: 'Cookies',
            text: 'Nós usamos cookies para garantir que você tenha a melhor experiência em nosso site. Ao continuar a navegar, você concorda com o uso de cookies.',
            showCancelButton: true,
            confirmButtonColor: '#D0782F',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceitar',
            cancelButtonText: 'Recusar',
            customClass: {
                popup: 'cookies__content',
                header: 'cookies__header',
                title: 'cookies__title',
                actions: 'cookies__actions',
                confirmButton: 'cookies__btn cookies__confirm',
                cancelButton: 'cookies__btn cookies__cancel',
            },
            buttonsStyling: false
        }).then((result: SweetAlertResult) => {
        if (result.isConfirmed) {
                setCookie("canopusCookies", "true");
            }
            if (result.isDismissed) {
                setCookie("canopusCookies", "false");
            }
        });
    }
});

function setCookie(nome: string, valor: string) {
    const dataAtual = new Date();
    const expiracao = new Date(dataAtual.setDate(dataAtual.getDate() + 365));
    valor = encodeURI(valor);

    // Formate a data de expiração corretamente
    const expiracaoString = expiracao.toUTCString();

    // Use template literals para uma string mais limpa
    document.cookie = `${nome}=${valor}; expires=${expiracaoString}; path=/`;
}

function getCookie(nome: string): string | null {
    const cookies = document.cookie;

    // Encontra e retorna o valor do cookie pelo nome
    const cookieMatch = cookies.match(new RegExp(`${nome}=([^;]+)`));
    return cookieMatch ? decodeURIComponent(cookieMatch[1]) : null;
}

