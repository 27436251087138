import axios from 'axios';
import { email, maxLength, required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import { validationMessage } from "vuelidate-messages";
import Vue from 'vue';
import Vuelidate from "vuelidate";

type NewsletterDto = {
    email: string
};

function initNewsletter() {
    let newsletterForm = document.querySelector<HTMLElement>(
        '.footer__novidades-form'
    );
    if (newsletterForm === null) {
        return;
    }
    Vue.use(Vuelidate);

    let formMessages = {
        required: () => "O campo é obrigatório",
        maxLength: ({ $params }: any) => `O campo deve possuir no máximo ${$params.maxLength.min} caracteres.`,
        email: () => "O e-mail inserido é inválido",
    };
    let validationRules = {
        email: {
            required,
            email,
            maxLength: maxLength(255)
        },
    };
    new Vue({
        el: newsletterForm,
        validations: validationRules,
        data: {
            email: null,
            loading: false,
        },
        methods: {
            validationMsg: validationMessage(formMessages, {}),
            reset() {
                this.email = null;
                this.$v.$reset();
            },
            getFormData(): NewsletterDto {
                return {
                    email: this.email,
                };
            },
            warnSubmitSuccess(): void {
                Swal.fire({
                    title: "Sucesso!",
                    text: "Sua inscrição foi feita com sucesso.",
                    icon: "success",
                    confirmButtonText: "Obrigado!",
                    confirmButtonColor: "#86ba40",
                });
            },
            warnSubmitFailure(): void {
                Swal.fire({
                    title: "Ops! Houve um problema",
                    text: "No momento não foi possível fazer sua inscrição, tente novamente mais tarde!",
                    icon: "error",
                    confirmButtonText: "Voltar",
                    confirmButtonColor: "#86ba40"
                });
            },
            startLoading(): void {
                this.loading = true;
            },
            stopLoading(): void {
                this.loading = false;
            },
            formDataIsValid(): boolean {
                this.$v.$touch();
                return !this.$v.$invalid;
            },
            handleSubmit(): void {
                this.startLoading();
                if (this.formDataIsValid() === false) {
                    //
                } else {
                    axios.post( /* url */ '/api/newsletters', /* data */ this.getFormData())
                        .then(this.warnSubmitSuccess)
                        .then(this.reset)
                        .catch(this.warnSubmitFailure);
                }
                this.stopLoading();
            },
        }
    })
}
initNewsletter();