import { scrollToTop } from "./helpers";

const steps = document.querySelectorAll<HTMLElement>(
    ".venda-de-terreno__card__form__step"
);
const stepBullets = document.querySelectorAll<HTMLElement>(".bullet");

const previousButton = document.getElementById("prev-step-venda-terreno");
const nextButton = document.getElementById("next-step-venda-terreno");

export function showCurrentStep(currentStep: number = 0) {
    scrollToTop();

    if (currentStep === 0) {
        previousButton?.classList.add("hide-button");
    } else {
        previousButton?.classList.remove("hide-button");
    }

    if (currentStep >= steps.length - 1) {
        nextButton?.classList.add("hide-button");
    } else {
        nextButton?.classList.remove("hide-button");
    }

    steps.forEach((step, index) => {
        step.classList.toggle("active-step", currentStep === index);
    });

    stepBullets.forEach((bullet, index) => {
        bullet.classList.toggle("active-bullet-step", currentStep >= index);
    });
}
