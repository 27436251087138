import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';

import VueMask from 'v-mask';
import VeeValidate from 'vee-validate';

Vue.use(VeeValidate, {
    locale: 'pt_BR',
});
Vue.use(VueCompositionAPI);
Vue.use(VueMask);

