import axios from "axios";
import { required, email, maxLength } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import { validationMessage } from "vuelidate-messages";
import Vue from "vue";
import VueMask from "v-mask";
import Vuelidate from "vuelidate";
import VueRecaptcha from "vue-recaptcha";
import Inputmask from "inputmask";

type TrabalheConosco = {
    vaga_id: number;
    uf_id: number;
    cidade_id: number;
    nome: string;
    email: string;
    curriculo: File | null;
    whatsapp: string;
    grecaptcha: string | null;
};

async function getCidades(estadoId: number) {
    try {
        const response = await fetch(`/api/estados/${estadoId}/cidades`);

        const cidades = await response.json();

        const selectCidade = document.getElementById("cidade_id");

        if (selectCidade) {
            const firstOption =
                selectCidade.querySelector<HTMLOptionElement>(
                    'option[value=""]'
                );

            selectCidade.innerHTML = "";

            (firstOption as HTMLOptionElement).innerHTML = "Carregando...";
            selectCidade.appendChild(firstOption as HTMLOptionElement);

            cidades.forEach((cidade) => {
                const option = document.createElement("option");
                option.value = cidade.id;
                option.textContent = cidade.titulo;
                selectCidade.appendChild(option);
            });

            (firstOption as HTMLOptionElement).innerHTML = "Selecione...";
        }
    } catch (error) {
        console.error("Erro ao buscar cidades:", error);
    }
}

async function listEstados() {
    const response = await fetch("/api/estados");

    const estados = await response.json();

    const selectEstados = document.getElementById("estado_id");

    const filteredEstados = estados.filter((estado) => {
        return (
            estado.titulo === "Maranhão" ||
            estado.titulo === "Piauí" ||
            estado.titulo === "Ceará"
        );
    });

    if (selectEstados) {
        filteredEstados.forEach((estado) => {
            const option = document.createElement("option");

            option.value = estado.id;
            option.textContent = estado.titulo;

            selectEstados.appendChild(option);
        });
    }
}

listEstados();

function initTrabalheConosco() {
    const trabalheConosco = document.querySelector<HTMLElement>(
        ".trabalhe-conosco__card__form"
    );

    if (trabalheConosco === null) {
        return;
    }

    Vue.use(Vuelidate);
    Vue.use(VueMask);

    const fileSize = (maxSize) => {
        return (value) => {
            if (!value) return true;
            const sizeMb = value.size / (1024 * 1024);
            return sizeMb <= maxSize;
        };
    };

    let formMessages = {
        required: () => "O campo é obrigatório",
        minLength: ({ $params }: any) =>
            `O campo deve possuir no mínimo ${$params.minLength.min} caracteres.`,
        maxLength: ({ $params }: any) =>
            `O campo deve possuir no máximo ${$params.maxLength.min} caracteres.`,
        email: () => "O e-mail inserido é inválido",
        sameAs: () => "Você deve aceitar as políticas de privacidade.",
        fileSize: () => "O arquivo deve ser menor que 2 MB.",
    };

    let validationRules = {
        nome: {
            required,
            maxLength: maxLength(255),
        },
        email: {
            required,
            email,
        },
        whatsapp: {
            required,
            maxLength: maxLength(15),
        },
        cidade_id: {
            required,
        },
        estado_id: {
            required,
        },
        curriculo: {
            required,
            fileSize: fileSize(2),
        },
        vaga: {
            required,
        },
        grecaptcha: { required },
    };

    const getMask = (value: string) => {
        return value.length < 15 ? "(##) ####-####?#" : "(##) #####-####";
    };

    new Vue({
        el: trabalheConosco,
        data: {
            nome: "",
            email: "",
            whatsapp: "",
            cidade_id: "",
            estado_id: "",
            vaga: "",
            curriculo: null,
            grecaptcha: null,
            loading: false,
            phoneMask: "(##) #####-####",
        },
        components: {
            VueRecaptcha,
        },
        validations: validationRules,
        watch: {
            telefone(value) {
                this.phoneMask = getMask(value);
            },
        },
        mounted() {
            const telefoneInput = document.querySelector("#whatsapp");
            if (telefoneInput) {
                Inputmask({ mask: "(99) 99999-9999" }).mask(telefoneInput);
            }
        },
        methods: {
            validationMsg: validationMessage(formMessages, {}),
            reset() {
                const labelCurriculo =
                    document.getElementById("curriculo-label");

                (labelCurriculo as HTMLInputElement).textContent =
                    "Anexar curriculo em .PDF";

                this.nome = "";
                this.email = "";
                this.whatsapp = "";
                this.estado_id = null;
                this.cidade_id = "";
                this.vaga = "";
                this.curriculo = null;
                this.grecaptcha = null;
                this.$refs.grecaptcha.reset();
                this.$v.$reset();
            },
            getCidades: (event) => {
                getCidades(event.target.value);
            },
            handleCurriculo(event): void {
                const input = event.target;
                const curriculo = input.files[0];

                if (curriculo) {
                    const reader = new FileReader();
                    reader.onload = () => {
                        this.curriculo = curriculo;
                        const labelCurriculo =
                            document.getElementById("curriculo-label");
                        if (labelCurriculo) {
                            labelCurriculo.textContent = curriculo.name;
                        }
                    };
                    reader.readAsDataURL(curriculo);
                }
            },
            getFormData(): TrabalheConosco {
                return {
                    nome: this.nome,
                    email: this.email,
                    whatsapp: this.whatsapp.replaceAll(/[^0-9]/g, ""),
                    uf_id: parseInt(this.estado_id),
                    cidade_id: parseInt(this.cidade_id),
                    vaga_id: parseInt(this.vaga),
                    curriculo: this.curriculo,
                    grecaptcha: this.grecaptcha,
                };
            },
            warnSubmitSuccess(): void {
                Swal.fire({
                    title: "Sucesso!",
                    text: "Seu currículo foi feito com sucesso.",
                    showCancelButton: false,
                    confirmButtonColor: "#D0782F",
                    confirmButtonText: "Obrigado(a)!",
                    customClass: {
                        popup: "modal__content--sucesso",
                        header: "modal__header modal__header--sucesso",
                        title: "modal__title--sucesso",
                        actions: "modal__actions",
                        confirmButton: "modal__btn modal__sucesso",
                    },
                    buttonsStyling: false,
                });
            },
            warnSubmitFailure(): void {
                Swal.fire({
                    title: "Ops! Houve um problema",
                    text: "No momento não foi possível enviar seu currículo, tente novamente mais tarde!",
                    showCancelButton: false,
                    confirmButtonColor: "#D0782F",
                    confirmButtonText: "OK",
                    customClass: {
                        popup: "modal__content--erro",
                        header: "modal__header modal__header--erro",
                        title: "modal__title--erro",
                        actions: "modal__actions",
                        confirmButton: "modal__btn modal__erro",
                    },
                    buttonsStyling: false,
                });
            },
            toggleLoading: () => {
                const textBtn = document.querySelector(
                    ".trabalhe-conosco__card__btn__text"
                );
                const spinnerBtn = document.querySelector(
                    ".trabalhe-conosco__card__btn__spinner"
                );

                textBtn?.classList.toggle(
                    "trabalhe-conosco__card__btn__text--hide"
                );
                spinnerBtn?.classList.toggle(
                    "trabalhe-conosco__card__btn__spinner--show"
                );
            },
            startLoading(): void {
                this.toggleLoading();
            },
            stopLoading(): void {
                this.toggleLoading();
            },
            formDataIsValid(): boolean {
                this.$v.$touch();
                return !this.$v.$invalid;
            },
            handleSubmit(): void {
                this.startLoading();
                if (this.formDataIsValid() === false) {
                    alert("Formulário inválido! Algum dado está faltando ou foi preenchido incorretamente.");
                } else {
                    axios
                        .post("/api/trabalhe-conosco", this.getFormData(), {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                        })
                        .then(this.warnSubmitSuccess)
                        .then(this.reset)
                        .catch(this.warnSubmitFailure);
                }
                this.stopLoading();
            },
            setGrecaptchaToken(grecaptchaToken: string): void {
                this.grecaptcha = grecaptchaToken;
            },
            warnRecaptchaExpiral(): void {
                Swal.fire({
                    title: "Aviso!",
                    text: "Sua verificação de Recaptcha expirou, tente novamente.",
                    showCancelButton: false,
                    confirmButtonColor: "#D0782F",
                    confirmButtonText: "OK",
                    customClass: {
                        popup: "modal__content--erro",
                        header: "modal__header modal__header--erro",
                        title: "modal__title--erro",
                        actions: "modal__actions",
                        confirmButton: "modal__btn modal__erro",
                    },
                    buttonsStyling: false,
                });
            },
            warnRecaptchaError(): void {
                Swal.fire({
                    title: "Ops! Houve um problema",
                    text: "Houve erros na verificação do seu Recaptcha!",
                    showCancelButton: false,
                    confirmButtonColor: "#D0782F",
                    confirmButtonText: "OK",
                    customClass: {
                        popup: "modal__content--erro",
                        header: "modal__header modal__header--erro",
                        title: "modal__title--erro",
                        actions: "modal__actions",
                        confirmButton: "modal__btn modal__erro",
                    },
                    buttonsStyling: false,
                });
            },
        },
    });
}

initTrabalheConosco();
