function activateHover(container, timeoutInMilliseconds = 300) {
    let timeoutId: any = 0;
    let lastClassName = "";

    const buttons = document.querySelectorAll("[data-class]");
    buttons.forEach((button) => {
        const className = button.getAttribute("data-class") as string;
        button.addEventListener("mouseenter", () => {
            clearTimeout(timeoutId);
            if (lastClassName !== "") {
                container.classList.remove(lastClassName);
            }
            container.classList.add(className);
            lastClassName = className;
        });

        button.addEventListener("mouseleave", () => {
            timeoutId = setTimeout(() => {
                container.classList.remove(className);
            }, timeoutInMilliseconds);
        });
    });
}

const container = document.querySelector(".home");
if (container) {
    //activateHover(container);
}
