import axios from 'axios';
import { email, minLength, maxLength, required } from "vuelidate/lib/validators";
import Swal, { SweetAlertResult } from "sweetalert2";
import { validationMessage } from "vuelidate-messages";
import Vue from 'vue';
import Vuelidate from "vuelidate";
import VueMask from "v-mask";

type CadastroDto = {
  nome: string
  documento: string
  telefone: string
  email: string
  imobiliaria: string
};

function initCadastro() {
  let cadastroForm = document.querySelector<HTMLElement>(
    '.corretor__secadastre-form'
  );
  if (cadastroForm === null) {
    return;
  }
  Vue.use(Vuelidate);
  Vue.use(VueMask);

  let formMessages = {
    required: () => "O campo é obrigatório",
    minLength: ({ $params }: any) => `O campo deve possuir no mínimo ${$params.minLength.min} caracteres.`,
    maxLength: ({ $params }: any) => `O campo deve possuir no máximo ${$params.maxLength.min} caracteres.`,
    email: () => "O e-mail inserido é inválido",
  };
  let validationRules = {
    nome: {
      required,
      maxLength: maxLength(255)
    },
    cpf: {
      required
    },
    whatsapp: {
      required,
      minLength: minLength(11),
      maxLength: maxLength(15)
    },
    email: {
      required,
      email,
      maxLength: maxLength(255)
    },
    imobiliaria: {
      required,
      maxLength: maxLength(255)
    },
  };
  let getMask = (value: string) => {
    return value.length < 15 ? '(##) ####-####?#' : '(##) #####-####';
  }

  new Vue({
    el: cadastroForm,
    data: {
      nome: '',
      cpf: '',
      whatsapp: '',
      email: '',
      imobiliaria: '',
      
      loading: false,
      phoneMask: '(##) ####-####?#',
    },
    validations: validationRules,
    watch: {
      whatsapp(value) {
        this.phoneMask = getMask(value);
      },
    },
    methods: {
      validationMsg: validationMessage(formMessages, {}),
      getFormData(): CadastroDto {
        return {
          nome: this.nome,
          documento: this.cpf.replaceAll(/[^0-9]/g, ''),
          telefone: this.whatsapp.replaceAll(/[^0-9]/g, ''),
          email: this.email,
          imobiliaria: this.imobiliaria,
        };
      },
      warnSubmitSuccess(): Promise<SweetAlertResult> {
        return Swal.fire({
          title: "Sucesso!",
          text: "O seu contato foi enviado com sucesso.",
          icon: "success",
          confirmButtonText: "Leve-me aos arquivos!",
          confirmButtonColor: "#86ba40",
        });
      },
      redirectToArquivos(): void {
        let caminho: Array<string> = window.location.pathname.split('/');
        if (caminho.length < 2) {
          return;
        }
        let origem: string = window.location.origin;
        let cidade: string = caminho[1];
        let cpf: string = this.cpf.replaceAll(/[^0-9]/g, '');
        window.location.assign( /* url */ `${origem}/${cidade}/corretores/arquivos?cpf=${cpf}`);
      },
      warnSubmitFailure(): void {
        Swal.fire({
          title: "Ops! Houve um problema",
          text: "No momento não foi possível enviar a sua mensagem, tente novamente mais tarde!",
          icon: "error",
          confirmButtonText: "Voltar",
          confirmButtonColor: "#86ba40"
        });
      },
      startLoading(): void {
        this.loading = true;
      },
      stopLoading(): void {
        this.loading = false;
      },
      formDataIsValid(): boolean {
        this.$v.$touch();
        return !this.$v.$invalid;
      },
      handleSubmit(): void {
        this.startLoading();
        if (this.formDataIsValid() === false) {
          //
        } else {
          axios.post( /* url */ '/api/corretores', /* data */ this.getFormData())
            .then(this.warnSubmitSuccess)
            .then(this.redirectToArquivos)
            .catch(this.warnSubmitFailure);
        }
        this.stopLoading();
      },
    }
  })
}
initCadastro();