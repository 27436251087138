function startSliderScript() {
    const tooltip = document.querySelector(".slider__tooltip");

    const range = document.querySelector(".slider__input");

    function scale(num, in_min, in_max, out_min, out_max) {
        return (
            ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
        );
    }

    function SliderLoading(isLoading: boolean) {
        const labelInput = document.querySelector(".slider__label");
        isLoading
            ? labelInput?.classList.add("slider__label--loading")
            : labelInput?.classList.remove("slider__label--loading");
    }

    range?.addEventListener("input", (e: any) => {
        tooltip?.classList.add("slider__tooltip--show");

        const labelValue = document.querySelector(".slider__value");
        const labelRange = document.querySelector(".slider__kilowatts");

        const value = +e?.target.value;
        const label = e?.target.nextElementSibling;

        const rangeWidth = getComputedStyle(e?.target).getPropertyValue(
            "width"
        );
        const labelWidth = getComputedStyle(label).getPropertyValue("width");
        // remove px
        const numWidth = +rangeWidth.substring(0, rangeWidth.length - 2);
        const numLabelWidth = +labelWidth.substring(0, labelWidth.length - 2);
        const max = +e?.target.max;
        const min = +e?.target.min;
        let left =
            value * (numWidth / max) -
            numLabelWidth / 2 +
            scale(value, min, max, 10, -10);

        label.style.left = `${left}px`;
        if (labelValue) {
            labelValue.innerHTML = `${new Intl.NumberFormat().format(value)}`;
            if (labelRange)
                labelRange.innerHTML = `${new Intl.NumberFormat().format(
                    value - 50
                )}kWh - ${new Intl.NumberFormat().format(value + 50)}kWh`;
        }
        SliderLoading(true);
    });

    range?.addEventListener("mouseup", (e: any) => {
        tooltip?.classList.remove("slider__tooltip--show");

        const labelValue = document.querySelector(".slider__value");
        const labelInput = document.querySelector(".slider__label");

        if (labelInput) {
            labelInput.innerHTML = `R$ ${labelValue?.innerHTML}`;
        }
        SliderLoading(false);
    });
}

startSliderScript();
