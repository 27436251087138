const form = document.querySelector<HTMLElement>(
    ".venda-de-terreno__card__form"
);
const previousButton = document.getElementById("prev-step-venda-terreno");
const nextButton = document.getElementById("next-step-venda-terreno");

const maxFiles = 6;

export function scrollToTop() {
    const target = document.querySelector<HTMLElement>(
        ".venda-de-terreno__reference"
    );

    target?.scrollIntoView({
        behavior: "smooth",
    });
}

export function setLabel(prevImageFiles: File[] = []) {
    const labelAlert = document.getElementById("label-alert");
    if (!labelAlert) return;

    labelAlert.innerHTML = `Você pode anexar até 6 fotos de no máximo 1MB por arquivo. <br> Você pode adicionar mais ${
        maxFiles - prevImageFiles.length
    } imagen(s)`;

    if (prevImageFiles.length === 0) {
        labelAlert.innerHTML = `Você pode anexar até 6 fotos de no máximo 1MB por arquivo. Formatos permitidos: PNG ou JPEG`;
    }
}

export function setupClickButtonsNav(handleNext, handlePrevious) {
    nextButton?.removeEventListener("click", handleNext);
    previousButton?.removeEventListener("click", handlePrevious);

    nextButton?.addEventListener("click", handleNext);
    previousButton?.addEventListener("click", handlePrevious);
}

export function prevendSubmitEnter() {
    form?.addEventListener("keydown", function (event) {
        if (event.key === "Enter") {
            event.preventDefault();
        }
    });
}

export function resetLabelPreviewImages() {
    const previewImages = document.querySelector<HTMLElement>(
        "#preview_images_terreno"
    );

    const labelAlert = document.getElementById("label-alert");

    const documentLabel = document.getElementById("documento-label");

    if (documentLabel) documentLabel.textContent = "";

    if (labelAlert)
        labelAlert.innerHTML = `Você pode anexar até 6 fotos de no mínimo 1MB por arquivo. Formatos permitidos: PNG ou JPEG`;

    if (previewImages) previewImages.innerHTML = "";
}
