import Vue from 'vue';
import { useCurrencyInput } from 'vue-currency-input';

Vue.component('money-input', {
    props: {
        modelValue: Number,
        options: Object,
        placeholder: String,
    },
    setup(props) {
        const { inputRef } = useCurrencyInput(props.options as any);

        return { inputRef };
    },
    template: `
        <div class="input input--number">
            <input type="text" class="input__field" :placeholder="placeholder" ref="inputRef" name="qual_e_o_seu_consumo_medio_de_energia_em_kwh">
        </div>
    `
});
