const modal = document.getElementById("warn-modal");
const modalWrapper = document.getElementById("warn-modal-wrapper");
const closeButtons = document.querySelectorAll(
    "#close-modal, #close-modal-button"
);
const loaderContainer = document.getElementById("loader-container");
const titleModal = document.getElementById("warn-modal-title");
const messageModal = document.getElementById("warn-modal-message");
const subtitleModal = document.getElementById("warn-modal-subtitle");

let currentModalState: "warn" | "load" | null = null;

function setModalContent(title: string, message: string, subtitle: string) {
    if (titleModal) titleModal.innerHTML = title;
    if (messageModal) messageModal.innerHTML = message;
    if (subtitleModal) subtitleModal.innerHTML = subtitle;
}

export function showWarnModal(
    title: string,
    message: string,
    subtitle: string
) {
    setModalContent(title, message, subtitle);
    hideLoader();
    showButtons();

    if (currentModalState !== "warn") {
        addCloseListeners();
    }

    currentModalState = "warn";
    openModal();
}

export function showLoadModal(
    title: string,
    message: string,
    subtitle: string
) {
    setModalContent(title, message, subtitle);
    showLoader();
    hideButtons();

    if (currentModalState === "warn") {
        removeCloseListeners();
    }

    currentModalState = "load";
    openModal();
}

function openModal() {
    modal?.classList.add("active-modal");
    modalWrapper?.classList.add("active-modal-wrapper");
}

export function closeModal() {
    modalWrapper?.classList.remove("active-modal-wrapper");
    modal?.classList.remove("active-modal");
}

function hideButtons() {
    closeButtons.forEach((button) => {
        (button as HTMLElement).style.display = "none";
    });
}

function showButtons() {
    closeButtons.forEach((button) => {
        (button as HTMLElement).style.display = "block";
    });
}

function showLoader() {
    (loaderContainer as HTMLElement).style.display = "block";
}

function hideLoader() {
    (loaderContainer as HTMLElement).style.display = "none";
}

function addCloseListeners() {
    modalWrapper?.addEventListener("click", closeOnOutsideClick);
    document.addEventListener("keydown", closeOnEscape);
    closeButtons.forEach((button) => {
        button.addEventListener("click", closeModal);
    });
}

function removeCloseListeners() {
    modalWrapper?.removeEventListener("click", closeOnOutsideClick);
    document.removeEventListener("keydown", closeOnEscape);
    closeButtons.forEach((button) => {
        button.removeEventListener("click", closeModal);
    });
}

function closeOnOutsideClick(event: Event) {
    if (event.target === modalWrapper) {
        closeModal();
    }
}

function closeOnEscape(event: KeyboardEvent) {
    if (event.key === "Escape") {
        closeModal();
    }
}
