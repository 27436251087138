const nav = document.querySelector<HTMLElement>(".nav");
const navCont = document.querySelector<HTMLElement>(".nav__wrap");
const navItem = document.querySelectorAll<HTMLElement>(".nav__click");
const menu = document.querySelector<HTMLElement>(".nav__menu");
const closenav = document.querySelector<HTMLElement>(".nav__close");
const home = document.querySelector<HTMLElement>(".home");

const triggerNav = (e) => {
    e.preventDefault();
    if (nav) {
        if (nav.className.indexOf("nav--open") > -1) {
            nav.className = nav.className.replace("nav--open", "").trim();
            home!.style.overflowY = "";
        } else {
            nav.className = nav.className + " nav--open";
            home!.style.overflowY = "hidden";
        }
    }
};

menu?.addEventListener("click", triggerNav);
closenav?.addEventListener("click", triggerNav);

for (const itens of navItem) {
    itens.addEventListener("click", () => {
        if (navCont) {
            if (nav) nav.classList.remove("nav--open");
        }
    });
}
