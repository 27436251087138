const allSelects = document.querySelectorAll(".input-select");

allSelects.forEach((select) => {
    const selectInner = select.lastChild;
    selectInner?.addEventListener("focus", () => {
        select.classList.add("input-select--changed");
    });
    selectInner?.addEventListener("blur", () => {
        select.classList.remove("input-select--changed");
    });
    selectInner?.addEventListener("change", () => {
        select.classList.remove("input-select--changed");
    });
});
