import {
    required,
    email,
    helpers,
    maxLength,
    minLength,
    sameAs,
} from "vuelidate/lib/validators";
import { reactive } from "@vue/composition-api";

const imagesValid = helpers.withParams(
    { type: "imagesValid" },
    (value) => Array.isArray(value) && value.length > 0
);

const onlyNumber = helpers.withParams(
    { type: "onlyNumber" },
    (value) => /^[0-9]+$/.test(value.trim()) || !value
);

const fileSize = (maxSize) => {
    return (value) => {
        if (!value) return true;
        const sizeMb = value.size / (1024 * 1024);
        return sizeMb <= maxSize;
    };
};

export let formMessages = {
    required: () => "O campo é obrigatório",
    onlyNumber: () => "Este campo aceita apenas números",
    imagesValid: () => "Anexe pelo menos uma imagem",
    minLength: ({ $params }: any) =>
        `O campo deve possuir no mínimo ${$params.minLength.min} caracteres.`,
    maxLength: ({ $params }: any) =>
        `O campo deve possuir no máximo ${$params.maxLength.min} caracteres.`,
    email: () => "O e-mail inserido é inválido",
    sameAs: () => "Você deve aceitar os termos e condições.",
    fileSize: () => "O arquivo deve ser menor que 1 MB.",
};

export const validationsRulesStepOne = {
    nome: { required, maxLength: maxLength(100) },
    fone: {
        required,
        maxLength: maxLength(20),
        minLength: minLength(8),
    },
    relacao: { required },
    email: { required, email },
    rua: { required, maxLength: maxLength(50) },
    numero: { required, onlyNumber },
    bairro: { required, maxLength: maxLength(55) },
    cidade_id: { required, maxLength: maxLength(55) },
    uf_id: { required, maxLength: maxLength(55) },
};

export const validationsRulesStepTwo = {
    area: { required, onlyNumber, maxLength: maxLength(10) },
    dimensao_frente: { required, onlyNumber, maxLength: maxLength(10) },
    dimensao_fundo: { required, onlyNumber, maxLength: maxLength(10) },
    dimensao_lado_esquerdo: { required, onlyNumber, maxLength: maxLength(10) },
    dimensao_lado_direito: { required, onlyNumber, maxLength: maxLength(10) },
    zoneamento_id: { required, maxLength: maxLength(10) },
    condicoes: { required, maxLength: maxLength(10) },
    murado: { required },
    arborizada: { required },
    construcoes: { required },
    descricao: { maxLength: maxLength(500) },
};

export const validationsRulesStepThree = {
    iptu: { required },
    pendencia: { required },
    detalhes_legais: { required },
    imagens_terreno: { imagesValid, reactive },
    documento_terreno: { fileSize: fileSize(1) },
};

export const validationsRulesStepFour = {
    comentario: { maxLength: maxLength(500) },
    termos: { sameAs: sameAs(() => true) },
    consentimento: { required },
    grecaptcha: { },
};
