const btnFaleComNossosCorretores = document.querySelector<HTMLElement>(
    "#fale-com-nossos-corretores-btn"
);
const simulacaoImovelNome = document.getElementById("simulacao_nome");
const wchat = document.querySelector("iframe");

function verificarHorario() {
    const agora = new Date();

    // Verificar se é segunda a sexta-feira
    const diaSemana = agora.getDay(); // 0 = Domingo, 1 = Segunda-feira, ..., 6 = Sábado
    if (diaSemana >= 1 && diaSemana <= 5) {
        // Dias úteis (segunda a sexta-feira)
        // Verificar se está dentro do intervalo de 8h às 18h30
        const horaAtual = agora.getHours();
        const minutosAtual = agora.getMinutes();
        if (
            (horaAtual > 8 || (horaAtual === 8 && minutosAtual >= 0)) &&
            (horaAtual < 18 || (horaAtual === 18 && minutosAtual <= 30))
        ) {
            console.log(wchat);
            //alert("Ação realizada!");
        } else {
            simulacaoImovelNome?.focus();
        }
    } else {
        simulacaoImovelNome?.focus();
    }
}
