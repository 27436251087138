import axios from "axios";
import {
    formMessages,
    validationsRulesStepOne,
    validationsRulesStepTwo,
    validationsRulesStepThree,
    validationsRulesStepFour,
} from "./validations";
import Vue from "vue";
import VueMask from "v-mask";
import Swal from "sweetalert2";
import { reactive } from "@vue/composition-api";
import Vuelidate from "vuelidate";
import VueRecaptcha from "vue-recaptcha";
import { validationMessage } from "vuelidate-messages";
import Inputmask from "inputmask";

import {
    setLabel,
    setupClickButtonsNav,
    resetLabelPreviewImages,
    prevendSubmitEnter,
} from "./helpers";
import { showWarnModal, showLoadModal, closeModal } from "./modal";
import { showCurrentStep } from "./steps";
import { setImagesUploaded } from "./preview_images";
import {
    stepOneData,
    stepTwoData,
    stepThreeData,
    stepFourData,
} from "./data_form";

const steps = document.querySelectorAll<HTMLElement>(
    ".venda-de-terreno__card__form__step"
);

let currentStep = 0;

let prevImageFiles: File[] = [];

const spanLoader = document.querySelector(
    ".venda-de-terreno__card__form__spinner"
);

const submitButton = document.getElementById("submitButton");

function startLoadFormButton() {
    spanLoader?.classList.add("venda-de-terreno__card__form__spinner--show");
    submitButton?.classList.add("btn--hide-text");
}

function stoptLoadFormButton() {
    spanLoader?.classList.remove("venda-de-terreno__card__form__spinner--show");
    submitButton?.classList.remove("btn--hide-text");
}

async function getCidades(estadoId: number) {
    try {
        const response = await fetch(`/api/estados/${estadoId}/cidades`);

        const cidades = await response.json();

        const selectCidade = document.getElementById("cidade_id");

        if (selectCidade) {
            const firstOption =
                selectCidade.querySelector<HTMLOptionElement>(
                    'option[value=""]'
                );

            selectCidade.innerHTML = "";

            (firstOption as HTMLOptionElement).innerHTML = "Carregando...";
            selectCidade.appendChild(firstOption as HTMLOptionElement);

            cidades.forEach((cidade) => {
                const option = document.createElement("option");
                option.value = cidade.id;
                option.textContent = cidade.titulo;
                selectCidade.appendChild(option);
            });

            (firstOption as HTMLOptionElement).innerHTML = "Selecione...";
        }
    } catch (error) {
        console.error("Erro ao buscar cidades:", error);
    }
}

async function listEstados() {
    const response = await fetch("/api/estados");

    const estados = await response.json();

    const selectEstados = document.getElementById("uf_id");

    const filteredEstados = estados.filter((estado) => {
        return (
            estado.titulo === "Maranhão" ||
            estado.titulo === "Piauí" ||
            estado.titulo === "Ceará" ||
            estado.titulo === "Pará"
        );
    });

    if (selectEstados) {
        filteredEstados.forEach((estado) => {
            const option = document.createElement("option");

            option.value = estado.id;
            option.textContent = estado.titulo;

            selectEstados.appendChild(option);
        });
    }
}

listEstados();

const handleNext = () => {
    let isValid;

    if (currentStep === 0) {
        isValid = stepOneValidation.validateStep();
    }

    if (currentStep === 1) {
        isValid = stepTwoValidation.validateStep();
    }

    if (currentStep === 2) {
        isValid = stepThreeValidation.validateStep();
    }

    if (currentStep === 3) {
        isValid = stepFourValidation.validateStep();
    }

    if (currentStep >= steps.length - 1) {
        return;
    }

    if (!isValid) {
        showWarnModal(
            "Faltam alguns campos antes de avançar",
            "Preencha todos os campos corretamente!",
            "Corrija e tente novamente."
        );
        return;
    }

    currentStep += 1;
    showCurrentStep(currentStep);
};

const handlePrevious = () => {
    if (currentStep <= 0) {
        return;
    }

    currentStep -= 1;
    showCurrentStep(currentStep);
    prevImageFiles;
};

const stepOneValidation = new Vue({
    el: ".step-one",
    data: stepOneData,
    validations: validationsRulesStepOne,
    mounted() {
        const telefoneInput = document.querySelector("#fone");
        if (telefoneInput) {
            Inputmask({ mask: "(99) 99999-9999" }).mask(telefoneInput);
        }
    },
    methods: {
        getCidades(event) {
            getCidades(event.target.value);
        },
        resetStep() {
            this.$v.$reset();

            this.nome = "";
            this.fone = "";
            this.email = "";
            this.relacao = null;
            this.uf_id = null;
            this.cidade_id = null;
            this.rua = "";
            this.numero = "";
            this.bairro = "";
        },
        getFormData() {
            return {
                nome: stepOneData.nome,
                telefone: stepOneData.fone,
                email: stepOneData.email,
                relacao_terreno_id: stepOneData.relacao,
                uf_id: stepOneData.uf_id,
                cidade_id: stepOneData.cidade_id,
                rua: stepOneData.rua,
                numero: stepOneData.numero,
                bairro: stepOneData.bairro,
            };
        },
        validateStep() {
            this.$v.$touch();
            return !this.$v.$invalid;
        },
        validationMsg: validationMessage(formMessages, {}),
    },
});

const stepTwoValidation = new Vue({
    el: ".step-two",
    data: stepTwoData,
    validations: validationsRulesStepTwo,
    methods: {
        getFormData() {
            return {
                area_total: stepTwoData.area,
                dimensao_frente: stepTwoData.dimensao_frente,
                dimensao_fundo: stepTwoData.dimensao_fundo,
                dimensao_lado_esquerdo: stepTwoData.dimensao_lado_esquerdo,
                dimensao_lado_direito: stepTwoData.dimensao_lado_direito,
                zoneamento_id: stepTwoData.zoneamento_id,
                condicoes_uso: stepTwoData.condicoes,
                is_murado: stepTwoData.murado === "sim" ? 1 : 0,
                is_arborizado: stepTwoData.arborizada === "sim" ? 1 : 0,
                has_construcoes_existentes:
                    stepTwoData.construcoes === "sim" ? 1 : 0,
                descricao_construcoes: stepTwoData.descricao,
            };
        },
        validateStep() {
            this.$v.$touch();
            return !this.$v.$invalid;
        },
        resetStep() {
            this.$v.$reset();

            this.area = "";
            this.dimensao_frente = "";
            this.dimensao_fundo = "";
            this.dimensao_lado_esquerdo = "";
            this.dimensao_lado_direito = "";
            this.zoneamento_id = "";
            this.condicoes = "";
            this.murado = "";
            this.arborizada = "";
            this.construcoes = "";
            this.descricao = "";
        },
        validationMsg: validationMessage(formMessages, {}),
    },
});

const stepThreeValidation = new Vue({
    el: ".step-three",
    data: stepThreeData,
    validations: validationsRulesStepThree,
    setup() {
        const stepThreeData = reactive({
            iptu: "",
            pendencia: "",
            detalhes_legais: "",
            documento_terreno: null,
            imagens_terreno: prevImageFiles.slice(0),
        });

        return {
            stepThreeData,
        };
    },
    methods: {
        getFormData() {
            return {
                has_iptu_em_dia: stepThreeData.iptu === "sim" ? 1 : 0,
                has_pendencias_judiciais:
                    stepThreeData.pendencia === "sim" ? 1 : 0,
                has_detalhes_legais:
                    stepThreeData.detalhes_legais === "sim" ? 1 : 0,
                file: stepThreeData.documento_terreno,
                "images[]": stepThreeData.imagens_terreno,
            };
        },
        resetStep() {
            this.$v.$reset();
            resetLabelPreviewImages();

            prevImageFiles = [];

            this.iptu = "";
            this.pendencia = "";
            this.detalhes_legais = "";
            this.documento_terreno = null;
            this.imagens_terreno = [];
        },
        validateStep() {
            this.stepThreeData.imagens_terreno = prevImageFiles.slice(0);
            stepThreeData.imagens_terreno = prevImageFiles.slice(0);

            this.$v.$touch();

            return !this.$v.$invalid;
        },
        validationMsg: validationMessage(formMessages, {}),
        handleChangeFileInputImages(e: Event) {
            prevImageFiles = setImagesUploaded(e, prevImageFiles);
            setLabel(prevImageFiles);
        },
        handleChangeFileInputDocument(event): void {
            const input = event.target;
            const documento_terreno = input.files[0];

            if (documento_terreno) {
                const reader = new FileReader();
                reader.onload = () => {
                    this.documento_terreno = documento_terreno;
                    const labelDocumento =
                        document.getElementById("documento-label");
                    if (labelDocumento) {
                        labelDocumento.textContent = documento_terreno.name;
                    }
                };
                reader.readAsDataURL(documento_terreno);
            }
        },
    },
});

const stepFourValidation = new Vue({
    el: ".step-four",
    data: stepFourData,
    validations: validationsRulesStepFour,
    setup() {
        const stepFourData = reactive({
            comentario: "",
            termos: false,
            consentimento: "",
            grecaptcha: null,
            loading: false,
            buttonId: "submitButton",
            disabled: false,
        });

        return {
            stepFourData,
        };
    },
    components: {
        VueRecaptcha,
    },
    methods: {
        handleComentarioChange(e) {
            if (this.$v.comentario) {
                this.stepFourData.comentario = e.target.value;
            }
        },
        handleTermosChange() {
            if (this.$v.termos) {
                this.stepFourData.termos = this.termos;
            }
        },
        handleConsentimentoChange() {
            if (this.$v.consentimento) {
                this.stepFourData.consentimento = this.consentimento;
            }
        },
        resetStep() {
            this.$v.$reset();

            this.comentario = "";
            this.termos = "";
            this.consentimento = "";
            this.grecaptcha = null;
        },
        resetForm() {
            currentStep = 0;
            stepOneValidation.resetStep();
            stepTwoValidation.resetStep();
            stepThreeValidation.resetStep();
            stepFourValidation.resetStep();
            showCurrentStep(0);
        },
        getFormData() {
            const stepOne = stepOneValidation.getFormData();
            const stepTwo = stepTwoValidation.getFormData();
            const stepThree = stepThreeValidation.getFormData();
            const stepFour = {
                grecaptcha: stepFourData.grecaptcha,
                valor_pretendido: "",
                is_aceite_termos: stepFourData.termos ? 1 : 0,
                observacoes: stepFourData.comentario,
                is_consentimento_contato:
                    stepFourData.consentimento === "sim" ? 1 : 0,
            };
            return {
                ...stepOne,
                ...stepTwo,
                ...stepThree,
                ...stepFour,
            };
        },
        validateStep() {
            this.$v.$touch();
            return !this.$v.$invalid;
        },
        handleSubmit() {
            // this.startLoading();
            const data = this.getFormData();
            delete data.stepThreeData;

            if (!this.validateStep()) {
                showWarnModal(
                    "Faltam alguns campos antes de avançar",
                    "Preencha todos os campos corretamente!",
                    "Corrija e tente novamente."
                );
                this.stopLoading();
            } else {
                axios
                    .post("/api/venda-terreno", data, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then(closeModal)
                    .then(this.warnSubmitSuccess)
                    .then(this.resetForm)
                    .catch(() => {
                        closeModal();
                        this.warnSubmitFailure();
                    });
            }
        },
        startLoading(): void {
            showLoadModal(
                "Estamos enviando os dados do seu Terreno",
                "Isso pode demorar um pouco.",
                "Aguarde um momento!"
            );
        },
        stopLoading(): void {
            closeModal();
        },
        setGrecaptchaToken(grecaptchaToken: string): void {
            this.grecaptcha = grecaptchaToken;
        },
        warnRecaptchaExpiral(): void {
            Swal.fire({
                title: "Aviso!",
                text: "Sua verificação de Recaptcha expirou, tente novamente.",
                showCancelButton: false,
                confirmButtonColor: "#D0782F",
                confirmButtonText: "OK",
                customClass: {
                    popup: "modal__content--erro",
                    header: "modal__header modal__header--erro",
                    title: "modal__title--erro",
                    actions: "modal__actions",
                    confirmButton: "modal__btn modal__erro",
                },
                buttonsStyling: false,
            });
        },
        warnRecaptchaError(): void {
            Swal.fire({
                title: "Ops! Houve um problema",
                text: "Houveram erros na verificação do seu Recaptcha!",
                showCancelButton: false,
                confirmButtonColor: "#D0782F",
                confirmButtonText: "OK",
                customClass: {
                    popup: "modal__content--erro",
                    header: "modal__header modal__header--erro",
                    title: "modal__title--erro",
                    actions: "modal__actions",
                    confirmButton: "modal__btn modal__erro",
                },
                buttonsStyling: false,
            });
        },
        warnSubmitSuccess(): void {
            showWarnModal(
                "Seu formulário foi enviado com sucesso!",
                "Em breve entraremos em contato com você.",
                "Obrigado!"
            );
        },
        warnSubmitFailure(): void {
            Swal.fire({
                title: "Ops! Houve um problema",
                text: "No momento não foi possível enviar seu formulário, tente novamente mais tarde!",
                showCancelButton: false,
                confirmButtonColor: "#D0782F",
                confirmButtonText: "OK",
                customClass: {
                    popup: "modal__content--erro",
                    header: "modal__header modal__header--erro",
                    title: "modal__title--erro",
                    actions: "modal__actions",
                    confirmButton: "modal__btn modal__erro",
                },
                buttonsStyling: false,
            });
        },
        validationMsg: validationMessage(formMessages, {}),
    },
});

// -- Fn de init -- //

function initVendaDeTerreno() {
    showCurrentStep(0);
    setupClickButtonsNav(handleNext, handlePrevious);
    prevendSubmitEnter();

    submitButton?.addEventListener("click", () => {
        stepFourValidation.handleSubmit();
        showLoadModal(
            "Estamos enviando os dados do seu Terreno",
            "Isso pode demorar um pouco.",
            "Aguarde um momento!"
        );
    });

    Vue.use(Vuelidate);
    Vue.use(VueMask);
}

initVendaDeTerreno();
