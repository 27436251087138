type StepOneData = {
    nome: string;
    fone: string;
    relacao: string;
    email: string;
    rua: string;
    numero: string;
    bairro: string;
    cidade_id: string;
    uf_id: string;
};

type StepTwoData = {
    area: string;
    dimensao_frente: string;
    dimensao_fundo: string;
    dimensao_lado_esquerdo: string;
    dimensao_lado_direito: string;
    zoneamento_id: string;
    condicoes: string;
    murado: string;
    arborizada: string;
    construcoes: string;
    descricao: string;
};

type StepThreeData = {
    iptu: string;
    pendencia: string;
    detalhes_legais: string;
    documento_terreno: File | null;
    imagens_terreno: File[];
};

type StepFourData = {
    comentario: string;
    termos: boolean;
    consentimento: string;
    grecaptcha: string | null;
    loading: boolean;
    buttonId: string;
    disabled: boolean;
};

export const stepOneData: StepOneData = {
    nome: "",
    fone: "",
    relacao: "",
    email: "",
    rua: "",
    numero: "",
    bairro: "",
    cidade_id: "",
    uf_id: "",
};

export const stepTwoData: StepTwoData = {
    area: "",
    dimensao_frente: "",
    dimensao_fundo: "",
    dimensao_lado_esquerdo: "",
    dimensao_lado_direito: "",
    zoneamento_id: "",
    condicoes: "",
    murado: "",
    arborizada: "",
    construcoes: "",
    descricao: "",
};

export let stepThreeData: StepThreeData = {
    iptu: "",
    pendencia: "",
    detalhes_legais: "",
    documento_terreno: null,
    imagens_terreno: [],
};

export const stepFourData: StepFourData = {
    comentario: "",
    termos: false,
    consentimento: "",
    grecaptcha: null,
    loading: false,
    buttonId: "submitButton",
    disabled: false,
};
