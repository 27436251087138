const collapsabler = (collapsable) => {
	const content: HTMLDivElement | null = collapsable.querySelector(".collapse__content")
	const height       = content?.scrollHeight
	const inlineHeight = content?.style.height
	const hasStyle     = content?.getAttribute("style") !== null

	if (! hasStyle || inlineHeight === "0px") {
		const previousCollapsed = document.querySelector('.collapse--open')

		if (previousCollapsed) {
			previousCollapsed.classList.remove("collapse--open")
			const previousContent: HTMLDivElement | null = previousCollapsed.querySelector(".collapse__content");

			previousContent!.style.height = '0px';
		}
		if (content) {
			content.style.height = `${height}px`
			collapsable.classList.add("collapse--open")
		}
	} else {
		if (content) {
			content.style.height = "0px"
			collapsable.classList.remove("collapse--open")
		}
	}
}
const collapse     = (collapsable) => {
	return event => collapsabler(collapsable)
}

const collapsables = document.querySelectorAll(".collapse")

collapsables.forEach(collapsable => {
	collapsable.addEventListener("click", collapse(collapsable))
})