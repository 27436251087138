const btnCadastre = document.querySelector<HTMLElement>(
    "#cadastrar-corretor-btn"
);
const btnFazerLogin = document.querySelector<HTMLElement>(
    "#login-corretor-btn"
);
const corretorLogin = document.querySelector<HTMLElement>(".corretor__login");
const corretorSeCadastre = document.querySelector<HTMLElement>(
    ".corretor__secadastre"
);

btnCadastre?.addEventListener("click", () => {
    if (corretorLogin && corretorSeCadastre) {
        corretorLogin.style.display = "none";
        corretorSeCadastre.style.display = "block";
    }
});

btnFazerLogin?.addEventListener("click", () => {
    if (corretorLogin && corretorSeCadastre) {
        corretorLogin.style.display = "block";
        corretorSeCadastre.style.display = "none";
    }
});
