import { setLabel } from "./helpers";
import { showWarnModal } from "./modal";

const maxFiles = 6;

export function renderPreviewImages(prevImageFiles: File[] = []) {
    const previewImages = document.querySelector<HTMLElement>(
        "#preview_images_terreno"
    );

    if (previewImages) previewImages.innerHTML = "";

    prevImageFiles.forEach((file, fileIndex) => {
        const reader = new FileReader();

        reader.onload = (e) => {
            const img = document.createElement("img");
            const div = document.createElement("div");
            const wrapper = document.createElement("div");
            const target = e.target;
            const result = target?.result;

            img.setAttribute("src", result as string);

            img.classList.add("active-image-preview");

            wrapper.addEventListener("click", (e) => {
                prevImageFiles.splice(fileIndex, 1);

                wrapper.remove();

                setLabel(prevImageFiles);

                renderPreviewImages(prevImageFiles);
            });

            div.classList.add("active-wrapper-preview");
            div.innerHTML = "Remover";

            wrapper.classList.add("active-wrapper");

            wrapper.appendChild(div);
            wrapper.appendChild(img);

            previewImages?.appendChild(wrapper);
        };

        reader.readAsDataURL(file);
    });
}

export function setImagesUploaded(
    e: Event,
    prevImageFiles: File[] = []
): File[] {
    const target = e.target as HTMLInputElement;
    const files = target.files;

    if (!files || files.length === 0) return prevImageFiles;

    let newFiles = Array.from(files);

    const hasInvalidImage = newFiles.some((file) => file.size > 1048576);

    if (hasInvalidImage) {
        showWarnModal(
            "Um ou mais arquivos não foram anexados",
            "Verifique se o tamanho do arquivo está de acordo com o permitido e tente novamente.",
            "Tamanho máximo permitido: 1Mb"
        );
    }

    newFiles = newFiles.filter((file) => file.size <= 1048576);

    if (prevImageFiles.length > maxFiles || newFiles.length > maxFiles) {
        newFiles = newFiles.slice(0, maxFiles);
        prevImageFiles = prevImageFiles.slice(0, maxFiles);
    }

    if (prevImageFiles.length > 0) {
        const filteredNewFiles = newFiles.filter((newFile) => {
            return !prevImageFiles.some(
                (prevFile) => prevFile.name === newFile.name
            );
        });

        prevImageFiles = [...prevImageFiles, ...filteredNewFiles];

        prevImageFiles = prevImageFiles.slice(0, maxFiles);
    } else {
        prevImageFiles = newFiles;
    }

    renderPreviewImages(prevImageFiles);

    return prevImageFiles;
}
