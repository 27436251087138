import axios, { AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from 'axios';
import Vue from 'vue';

type Arquivo = {
    titulo: string,
    vinculo: string,
    createdAt: string,
    downloadUrl: string,
};
type Arquivos = Array<Arquivo>;

type CidadeDoGrupo = {
    id: number,
    titulo: string,
};
type CidadesDoGrupo = Array<CidadeDoGrupo>;

type Imovel = {
    id: number,
    titulo: string,
};
type Imoveis = Array<Imovel>;

type Id = number;
type Optional<T> = T | null;

function initArquivos(): void {
    let arquivosPage: HTMLElement | null = document.querySelector(
        '.corretor__tabela'
    );
    if (arquivosPage === null) {
        return;
    }
    new Vue({
        el: arquivosPage,
        data: {
            cpf: null as Optional<string>,

            arquivos: [] as Arquivos,
            cidadesDoGrupo: [] as CidadesDoGrupo,
            imoveis: [] as Imoveis,
            
            cidadeDoGrupoId: null as Optional<Id>,
            imovelId: null as Optional<Id>,
            categoriaId: null as Optional<Id>,

            loadingArquivos: false,
            loadingCidadesDoGrupo: false,
            loadingImoveis: false,
            loading: false,
        },
        computed: {
            cidadesDoGrupoFiltradas(): string {
                if (this.loadingCidadesDoGrupo === true) {
                    return 'Carregando Cidades';
                }
                if (this.cidadesDoGrupo.length === 0) {
                    return 'Não foram encontradas Cidades';
                }
                let cidadeDoGrupo: CidadeDoGrupo | undefined = this.cidadesDoGrupo.find((cidadeDoGrupo: CidadeDoGrupo): boolean => cidadeDoGrupo.id === this.cidadeDoGrupoId);
                if (cidadeDoGrupo === undefined) {
                    return 'Todas';
                }
                return cidadeDoGrupo.titulo;
            },
            selecaoDeImoveisEstaDesabilitada(): boolean {
                return (this.cidadeDoGrupoId === null) || (this.imoveis.length === 0);
            },
            tituloDaOpcaoPadraoDeImoveis(): string {
                if (this.loadingImoveis === true) {
                    return 'Carregando imóveis';
                }
                if (this.cidadeDoGrupoId === null) {
                    return 'Selecione uma Cidade';
                }
                if (this.imoveis.length === 0) {
                    return 'Nenhum imóvel encontrado';
                }
                return 'Todos';
            }
        },
        watch: {
            cidadeDoGrupoId(currentCidadeDoGrupoId: Optional<Id>, previousCidadeDoGrupoId: Optional<Id>): void {
                this.setImovelIdWithoutTriggeringWatcher(null);
                this.imoveis = [];
                if (currentCidadeDoGrupoId === null) {
                    this.loadAllArquivos();
                } else {
                    this.loadImoveisForCidadeDoGrupoId(currentCidadeDoGrupoId);
                    this.loadArquivosForCidadeDoGrupoId(currentCidadeDoGrupoId);
                }
            },
            imovelId(currentImovelId: Optional<Id>, previousImovelId: Optional<Id>): void {
                if (currentImovelId === null) {
                    this.loadArquivosForCidadeDoGrupoId(this.cidadeDoGrupoId);
                } else {
                    this.loadArquivosForImovelId(currentImovelId);
                }
            },
        },
        methods: {
            startLoadingArquivos(): void {
                this.loadingArquivos = true;
            },
            stopLoadingArquivos(): void {
                this.loadingArquivos = false;
            },
            setArquivos(arquivos: Arquivos): void {
                this.arquivos = arquivos;
            },
            async fetchArquivosForCategoria(categoriaId: Optional<Id>): Promise<Arquivos> {
                let params = new URLSearchParams({
                    categoria_id: `${categoriaId}`,
                });
                let headers: AxiosRequestHeaders = {
                    'Authorization': this.cpf,
                };
                let config: AxiosRequestConfig = {
                    params,
                    headers,
                };
                let axiosResponse: AxiosResponse = await axios.get('/api/arquivos', config);
                return axiosResponse.data.arquivos;
            },
            async fetchArquivosForCidadeDoGrupoIdAndCategoria(cidadeDoGrupoId: Id, categoriaId: Optional<Id>): Promise<Arquivos> {
                let params = new URLSearchParams({
                    categoria_id: `${categoriaId}`,
                });
                let headers: AxiosRequestHeaders = {
                    'Authorization': this.cpf,
                };
                let config: AxiosRequestConfig = {
                    params,
                    headers,
                };
                let axiosResponse: AxiosResponse = await axios.get(`/api/cidades-do-grupo/${cidadeDoGrupoId}/arquivos`, config);
                return axiosResponse.data.arquivos;
            },
            async fetchArquivosForImovelIdAndCategoria(imovelId: Id, categoriaId: Optional<Id>): Promise<Arquivos> {
                let params = new URLSearchParams({
                    categoria_id: `${categoriaId}`,
                });
                let headers: AxiosRequestHeaders = {
                    'Authorization': this.cpf,
                };
                let config: AxiosRequestConfig = {
                    params,
                    headers,
                };
                let axiosResponse: AxiosResponse = await axios.get(`/api/imoveis/${imovelId}/arquivos`, config);
                return axiosResponse.data.arquivos;
            },
            loadAllArquivos(): void {
                this.startLoadingArquivos();
                this.fetchArquivosForCategoria(this.categoriaId).then(this.setArquivos).finally(this.stopLoadingArquivos);
            },
            loadArquivosForCidadeDoGrupoId(cidadeDoGrupoId: Id): void {
                this.startLoadingArquivos();
                this.fetchArquivosForCidadeDoGrupoIdAndCategoria(cidadeDoGrupoId, this.categoriaId).then(this.setArquivos).finally(this.stopLoadingArquivos);
            },
            loadArquivosForImovelId(imovelId: Id): void {
                this.startLoadingArquivos();
                this.fetchArquivosForImovelIdAndCategoria(imovelId, this.categoriaId).then(this.setArquivos).finally(this.stopLoadingArquivos);
            },

            startLoadingCidadesDoGrupo(): void {
                this.loadingCidadesDoGrupo = true;
            },
            stopLoadingCidadesDoGrupo(): void {
                this.loadingCidadesDoGrupo = false;
            },
            setCidadesDoGrupo(cidadesDoGrupo: CidadesDoGrupo): void {
                this.cidadesDoGrupo = cidadesDoGrupo;
            },
            async fetchCidadesDoGrupo(): Promise<CidadesDoGrupo> {
                let axiosResponse: AxiosResponse = await axios.get('/api/cidades-do-grupo');
                return axiosResponse.data.cidadesDoGrupo;
            },
            loadCidadesDoGrupo(): void {
                this.startLoadingCidadesDoGrupo();
                this.fetchCidadesDoGrupo().then(this.setCidadesDoGrupo).finally(this.stopLoadingCidadesDoGrupo);
            },

            startLoadingImoveis(): void {
                this.loadingImoveis = true;
            },
            stopLoadingImoveis(): void {
                this.loadingImoveis = false;
            },
            setImoveis(imoveis: Imoveis): void {
                this.imoveis = imoveis;
            },
            async fetchImoveisForCidadeDoGrupoId(cidadeDoGrupoId: Id): Promise<Imoveis> {
                let axiosResponse: AxiosResponse = await axios.get(`/api/cidades-do-grupo/${cidadeDoGrupoId}/imoveis`);
                return axiosResponse.data.imoveis;
            },
            loadImoveisForCidadeDoGrupoId(cidadeDoGrupoId: Id): void {
                this.startLoadingImoveis();
                this.fetchImoveisForCidadeDoGrupoId(cidadeDoGrupoId).then(this.setImoveis).finally(this.stopLoadingImoveis);
            },

            setImovelIdWithoutTriggeringWatcher(imovelId: Optional<Id>): void {
                this.$data.imovelId = imovelId;
            },
            initCpf() {
                let urlSearchParams = new URLSearchParams(window.location.search);
                this.cpf = urlSearchParams.get('cpf');
            },
        },
        mounted() {
            this.initCpf();
            this.loadCidadesDoGrupo();
            this.loadAllArquivos();
        },
    })
}
initArquivos();